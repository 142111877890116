import {initializeApp} from "@firebase/app";
import {getMessaging, getToken} from "@firebase/messaging";
import {useEffect, useState} from "react";
import useServiceWorker from "./useServiceWorker";
import {firebaseConfig, firebaseVapid} from "./firebase";

const initFirebase = async (registration: ServiceWorkerRegistration): Promise<string> => {
    const app = initializeApp(firebaseConfig);

    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging(app);

    let currentToken;
    try {
        currentToken = await getToken(messaging, {
            vapidKey: firebaseVapid,
            serviceWorkerRegistration: registration,
        })
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }

    if (!currentToken) {
        throw new Error('No registration token available. Request permission to generate one.');
    }

    console.log('current token for client: ', currentToken);
    let fd = new FormData();
    fd.append('token', currentToken);
    await fetch('https://dev.leiterm.app/api/token', {
        method: 'POST',
        body: fd,
    });
    window.localStorage.setItem('fcmToken', JSON.stringify(currentToken));
    return currentToken;
}

const useFirebase = (): [string | null, boolean, Error | null] => {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const registration = useServiceWorker();

    useEffect(() => {
        setLoading(true);
        if (!registration) {
            return;
        }
        initFirebase(registration).then((token) => {
            setToken(token);
            setError(null);
            console.log("Firebase initialized");
        }).catch((err) => {
            setError(err);
            console.error("Firebase initialization failed", err);
        }).finally(() => {
            setLoading(false);
        });
    }, [registration]);

    return [token, loading, error];
};

export default useFirebase;
