export const firebaseConfig = {
    apiKey: "AIzaSyA2JOYHvlOQddgeQpklC8SCSQZaqXZ84Do",
    authDomain: "react-firebase-test-5b594.firebaseapp.com",
    projectId: "react-firebase-test-5b594",
    storageBucket: "react-firebase-test-5b594.appspot.com",
    messagingSenderId: "462220748876",
    appId: "1:462220748876:web:a0bffdc45acb054c956ce9"
};

export const firebaseVapid = 'BJDnBbpjPc7vGsd6ppacnhHZNlc5gtUXNHX-e2o_jUfPBWiBOHpBO9ZAvOQk-eSH35Pw4c9TBFTN6Fz8G0ik24g';
