import {useEffect, useState} from "react";
import {getRegistration, register} from "./serviceWorkerRegistration";

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

const useServiceWorker = () => {
    const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);

    getRegistration(swUrl).then((reg) => {
        if (!reg) {
            console.log("Service worker not (yet?) registered");
            return;
        }
        setRegistration(reg);
    });

    useEffect(() => {
        register(swUrl).then((reg) => {
            if (!reg) {
                console.log("Service worker not (yet?) registered");
                return;
            }
            console.log("Registered service worker", reg);
            setRegistration(reg);
        }).catch((err) => {
            console.error("Service worker registration failed", err);
        });
    }, []);

    return registration;
};

export default useServiceWorker;
