import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';

import {getMessaging, onMessage} from "firebase/messaging";
import useFirebase from "./useFirebase";
import {initializeApp} from "firebase/app";

function App() {
    const [token, loading, error] = useFirebase();

    useEffect(() => {
        console.log("Effect");
        if (token) {
            console.log("Service worker registered");
        }
        if (loading) {
            console.log("Service worker registration in progress");
        }
        if (error) {
            console.error("Service worker registration failed", error);
        }
    }, [token, loading, error]);

    useEffect(() => {
        if (!token) {
            return;
        }
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // ...
        });
    }, [token]);

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Registration token:
                    <br/>
                    <div>
                        {token || "Loading..."}
                    </div>
                </p>
            </header>
        </div>
    );
}

export default App;
